import React from "react";
import { Link } from "gatsby";
import "/src/assets/scss/components/text-image.scss";

function TextImage(props) {
  const { image, imageAlt, title, text } = props;
  const paragraphs = text.map((paragraph, index) =>
    <p key={index}>
      {paragraph}
    </p>
  );
  return (
    <section className="text-image">
      <div className="container">
        <div className="text-image-row">
          <div className="image-col">
            <img src={image} alt={imageAlt} className="text-image-img" />
          </div>
          <div className="text-col">
            <h2 className="title-2">{title}</h2>
            <div className="text-image-copy">{paragraphs}</div>
            <Link to="/products" className="btn">
              SEE OUR PRODUCTS
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TextImage;
