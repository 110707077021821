import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/structure/Layout";
import TextImage from "../components/TextImage";
import HeroAbout from "../components/heros/HeroAbout";
import aboutImage from "../static/images/img-fishing-net.jpg";

function About() {
  const textImageSrc = aboutImage;
  const textImageAlt = "Commercial fishing nets";
  const textImageTitle = "ABOUT US";
  const textImageCopy = [
    "Fantastic Fish was founded in 2003 as a seafood importer and wholesaler, with the mission of providing premium seafood products to our customers both nationwide and internationally. Our commitment to quality, integrity, and customer service are the backbone that has made Fantastic Fish Company a popular and dependable source for seafood products in South Florida. With over 75 years of experience we understand your needs and how to fulfill them.",
    "Throughout the years, we have partnered and built long-term relationships with our suppliers around the globe. We have done this to provide you with the highest of quality seafood on a consistent basis, even in those times when production is scarce. To the best of our ability, we pledge to you, to go the extra mile to keep you supplied when you need it the most. We promise to source your needs with sustainable and eco-friendly procurement methods that benefit all. We do not take advantage of the small independent fishermen or exploit our natural resources for the sake of a profit. Unlike our larger competitors, we do not gas our fish with carbon monoxide to enhance the physical appearance of our products. Fresh and natural is our promise to you.",
    <strong>We are not the largest, but we are the best!</strong>,
  ];

  return (
    <div className="page-about">
      <Helmet>
        <html lang="en" />
        <title>Fantastic Fish Company | About</title>
        <meta
          name="description"
          content="Fantastic Fish was founded in 2003 as a seafood importer and
          wholesaler, with the mission of providing premium seafood products to
          our customers both nationwide and internationally."
        />
      </Helmet>
      <Layout>
        <HeroAbout />
        <main id="main-content" tabIndex="-1">
          <TextImage
            key={textImageTitle}
            image={textImageSrc}
            imageAlt={textImageAlt}
            title={textImageTitle}
            text={textImageCopy}
          />
        </main>
      </Layout>
    </div>
  );
}

export default About;
